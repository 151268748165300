import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { first } from 'rxjs';

export interface ConfigurableSuccess {
  /**
   * The label for the success
   */
  label: string;

  /**
   * Display success
   * (optional) choose a field to display (defaults to `message`)
   */
  display?: string | boolean;

  /**
   * When the success is displayed, should the message been translated
   */
  translate?: boolean;

  /**
   * When the success is translated, which scope to use
   */
  translateScope?: string;

  /**
   * Dynamic data for the translation
   */
  params?: any;
}

@Injectable({ providedIn: 'root' })
export class SuccessHandlerService {
  snackBar = inject(MatSnackBar);
  transloco = inject(TranslocoService);

  public displaySuccess(success: ConfigurableSuccess): void {
    const isMobile = window.innerWidth <= 768;
    const duration =  isMobile ? 2500 : 5000;
    const position = isMobile ? 'bottom' : 'top';
    if (success.translate) {
      this.transloco
        .selectTranslate(success.label, success.params ? success.params : {}, success.translateScope)
        .pipe(first())
        .subscribe((translation: string) => {
          this.snackBar.open(translation, '', {
            verticalPosition: position,
            duration: duration,
            horizontalPosition: 'right',
            panelClass: ['success'],
          });
        });
      return;
    }

    this.snackBar.open(success.label, '', {
      duration: duration,
      verticalPosition: position,
      horizontalPosition: 'right',
      panelClass: ['success'],
    });
  }
}
